import React from 'react';
import ProductCard from '../components/ProductCard';

// Import your pickle images
import pickle1 from '../assets/Fishpickle.png';
import pickle2 from '../assets/Bombayduckpickle.png';

const pickles = [
  {
    image: pickle1,
    name: 'Fish-Roe Pickle',
    description: 'Spicy, tangy fish roe in a briny marinade.',
  },
  {
    image: pickle2,
    name: 'Bombay Ducks Pickle',
    description: 'Tangy, spicy Bombay duck for a bold kick.',
  },
];

const PicklesPage = () => {
  return (
    <div className="p-8">
      <div className="mb-12 text-center">
        <h1 className="text-4xl font-bold text-primary">Non-Veg Pickles</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-60"> {/* Increased gap between columns */}
        {pickles.map((pickle, index) => (
          <div className="p-20 border border-gray-300 rounded-lg shadow-lg"> {/* Increased padding around the card */}
            <ProductCard
              key={index}
              image={pickle.image}
              name={pickle.name}
              description={pickle.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PicklesPage;
