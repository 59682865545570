import React from 'react';
import aboutUsImage from '../assets/AboutUs.jpg'; // Import the poster image

const AboutUs = () => {
  return (
    <div id="About" className="bg-gradient-to-r from-orange-50 via-orange-100 to-orange-200 min-h-screen flex items-center justify-center p-8">
      {/* Main container with image on the left and content on the right */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center transform transition-transform hover:scale-105 duration-700 ease-out">
        
        {/* Left Section: Image without gradient overlay */}
        <div className="relative w-full h-full shadow-2xl rounded-lg transform hover:rotate-2 transition-transform duration-500 ease-out">
          <img
            src={aboutUsImage}
            alt="Faramz Poster"
            className="w-full h-auto rounded-lg object-cover"
          />
        </div>

        {/* Right Section: Content with 3D shadows */}
        <div className="flex flex-col justify-center p-8 bg-white bg-opacity-95 rounded-lg shadow-2xl transform hover:translate-x-2 hover:translate-y-2 transition-transform duration-500 ease-out">
          <h1 className="text-5xl font-bold text-orange-800 mb-6 drop-shadow-lg">
            About FARAMZ
          </h1>
          <p className="text-lg text-gray-700 mb-6 leading-relaxed drop-shadow-md">
            Our parent company, <strong>E.F. Kolah & Sons</strong>, was established during British rule by Shri. Eduljee Faramjee Kolah. He began crafting Natural Brewed Vinegar using sugarcane juice as the brewing medium, a practice rare in cities like Bombay and Calcutta, which typically offered only imported British brands like Heinz and Crosse & Blackwell.
          </p>
          <p className="text-lg text-gray-700 mb-6 leading-relaxed drop-shadow-md">
            Over time, vinegar production became widespread in homes across India’s remote areas, particularly in states like U.P. and M.P. The fermentation and maturation process—called the New Orleans theory—takes five months to transform fresh raw juice into genuine natural vinegar.
          </p>
          <p className="text-lg text-gray-700 mb-6 leading-relaxed drop-shadow-md">
            In 1950, Framroze J. Kolah, the next generation, expanded the business by adding traditional condiments, pickles, and chutneys to the product line. What began as a home-scale operation gradually evolved into a micro-scale business.
          </p>
          <p className="text-lg text-gray-700 mb-6 leading-relaxed drop-shadow-md">
            Today, the company is led by Mr. Yazad F. Kolah, a seasoned professional with over 25 years of experience, alongside Mrs. Rasna Yazad Kolah, a microbiologist who oversees the manufacturing processes. Together, they have launched 11 varieties of Insta-Masalas, in addition to a range of pickles, chutneys, and brewed vinegar, positioning the company for growth.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed drop-shadow-md">
            Now, with nearly 30 different varieties of pickles, chutneys, Insta-Masalas, and genuine vinegar, FARAMZ is poised for expansion, seeking financing to turn the team's collective vision into reality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
