import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';  // Import Swiper React components
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/autoplay';  // Autoplay CSS
import 'swiper/css/navigation';  // Navigation CSS
import 'swiper/css/pagination';  // Pagination CSS

// Import Swiper modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules'; // Correct import for Swiper 11.x
import CarouselCard from '../components/carouselcard';  // Import the CarouselCard component

// Import your product images
import product1 from '../assets/Butter chicken mix.png';
import product2 from '../assets/Currymasala.png';
import product3 from '../assets/Dhanshak masala mix.png';
import product4 from '../assets/Golden fried onion.png';
import product5 from '../assets/Kheema masala.png';
import product6 from '../assets/Mughlai masala mix.png';
import product7 from '../assets/Salli Chicken.png';
import product8 from '../assets/Garlicchutney.png';
import product9 from '../assets/Ginger Garlic Chilli  paste.png';
import product10 from '../assets/Vindaloo Masala Mix.png';
import product11 from '../assets/Biryani Masala Mix.png';
import product12 from '../assets/Tandori masala mix.png';

// List of products
const products = [
  { name: 'Butter Chicken Mix', image: product1 },
  { name: 'Curry Masala Mix', image: product2 },
  { name: 'Dhanshak Masala Mix', image: product3 },
  { name: 'Golden Fried Onion', image: product4 },
  { name: 'Kheema Masala Mix', image: product5 },
  { name: 'Mughlai Masala Mix', image: product6 },
  { name: 'Salli Chicken/Mutton', image: product7 },
  { name: 'Garlic Chutney', image: product8 },
  { name: 'Ginger Garlic Chilli paste', image: product9 },
  { name: 'Vindaloo Masala Mix', image: product10 },
  { name: 'Biryani Masala Mix', image: product11 },
  { name: 'Tandoori Masala Mix', image: product12 },
];

const ProductCarousel = () => {
  const headingStyle = {
    color: '#FF770E', // Orange color
  };

  return (
    <div className="p-8">
      <h2 className="text-4xl font-bold text-center mb-6" style={headingStyle}>
        Insta Masala's
      </h2>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]} // Import and use the necessary modules
        spaceBetween={20} // Space between slides
        loop={true} // Enable looping
        autoplay={{ delay: 2000, disableOnInteraction: false }} // Enable autoplay
        navigation // Enable navigation buttons
        pagination={{ clickable: true }} // Enable clickable pagination dots
        breakpoints={{
          // Breakpoints for responsive design
          320: { slidesPerView: 1, spaceBetween: 10 }, // Mobile: 1 slide at a time
          640: { slidesPerView: 2, spaceBetween: 20 }, // Small devices: 2 slides
          768: { slidesPerView: 3, spaceBetween: 20 }, // Tablets: 3 slides
          1024: { slidesPerView: 4, spaceBetween: 20 }, // Desktops: 4 slides
          1280: { slidesPerView: 5, spaceBetween: 20 }, // Larger screens: 5 slides
        }}
      >
        {products.map((product, index) => (
          <SwiperSlide key={index}>
            <CarouselCard image={product.image} name={product.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductCarousel;
