// src/pages/OurWorks.js

import React from 'react';

// Import your local images
import project1Image from '../assets/Ourwork1.jpeg';
import project2Image from '../assets/Ourwork2.jpeg'; 
import project3Image from '../assets/Ourwork3.jpeg';
import project4Image from '../assets/Ourwork4.jpeg'; 
import project5Image from '../assets/Ourwork5.jpeg';
import project6Image from '../assets/Ourwork6.jpeg'; 
import project7Image from '../assets/ourwork7.jpeg';
import project8Image from '../assets/ourwork8.jpeg'; 

const works = [
  {
    image: project1Image, // Use the imported image
  },
  {
    image: project2Image, // Use the imported image
  },
  {
    image: project3Image, // Use the imported image
  },
  {
    image: project4Image, // Use the imported image
  },
  {
    image: project8Image, // Use the imported image
  },
  {
    image: project6Image, // Use the imported image
  },
  {
    image: project7Image, // Use the imported image
  },
  {
    image: project5Image, // Use the imported image
  },
  // Add more works here
];

const OurWorks = () => {
  return (
    <section id="our-works" className="bg-gray-50 py-12">
      <div className="container mx-auto">
        {/* Heading */}
        <h1 className="text-4xl font-bold text-center text-black mb-8">Our Works</h1>

        {/* Gallery layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {works.map((work, index) => (
            <div key={index} className="group relative">
              {/* Image */}
              <img
                src={work.image}
                alt={`Work ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg transition duration-300 ease-in-out transform group-hover:scale-105"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurWorks;
