// src/components/CarouselCard.js
import React from 'react';

const CarouselCard = ({ image, name, description }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 text-center">
      <img
        src={image}
        alt={name}
        className="w-64 h-96 mx-auto mb-4 object-cover" // Adjust width and height to create a poster ratio (3:4)
      />
      <h2 className="text-lg font-semibold text-gray-800">{name}</h2>
      <p className="text-gray-600 mt-2">{description}</p>
    </div>
  );
};

export default CarouselCard;
