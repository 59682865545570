import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Testimonial from './pages/Testimonial';
import SocialMedia from './components/SocialMedia';
import ProductCarousel from './pages/ProductCarousel';  
import OurWorks from '../src/pages/Ourwork';  
import Picklespage from '../src/pages/PicklesPage';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Home />
        <About />
        <Products />
        <Picklespage/>
        <ProductCarousel />
        <OurWorks />
        <Testimonial />
        <Contact />
        <SocialMedia />
      </main>
      <Footer />
    </div>
  );
}

export default App;
