import React, { useState } from 'react';
import logo from '../assets/faramz-logo.png';

const Header = () => {
  // State to toggle mobile menu visibility
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <img src={logo} alt="Faramz Pickle" className="h-12" />

        {/* Hamburger menu for mobile */}
        <button
          className="md:hidden text-primary focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Navigation links (visible on desktop, hidden on mobile unless menu is toggled) */}
        <nav
          className={`${
            isOpen ? 'block' : 'hidden'
          } md:flex space-x-6 text-primary font-semibold`}
        >
          <ul className="flex flex-col md:flex-row md:space-x-6 mt-4 md:mt-0">
            <li><a href="#Home" className="block py-2 md:py-0">Home</a></li>
            <li><a href="#About" className="block py-2 md:py-0">About Us</a></li>
            <li><a href="#products" className="block py-2 md:py-0">Products</a></li>
            <li><a href="#testimonials" className="block py-2 md:py-0">Testimonials</a></li> {/* New Testimonials Link */}
            <li><a href="#contact" className="block py-2 md:py-0">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
