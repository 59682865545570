import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-orange-500 bg-opacity-70 text-center py-4 text-white">
      <p>© 2024 Faramz Pickles. All Rights Reserved. Powered by {' '}
        <a
          href="https://enego.co.in/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-white"
        >
          Enego Services Pvt Ltd
        </a></p>
    </footer>
  );
};

export default Footer;
