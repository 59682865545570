import React from 'react';
import amla from '../assets/amla.png'; 
import bael from '../assets/bael.png'; 
import carrot from '../assets/carrot.png';
import garlic from '../assets/garlic.png'; 
import ginger from '../assets/ginger.png'; 
import karanda from '../assets/karanda.png'; 
import lemon from '../assets/lemon.png'; 
import mango from '../assets/mango 2.png';
import logo from '../assets/Faramzcenterlogo.png'; // Add your logo here
import './HeroSection.css'; // Custom CSS for the orbit layout

const HeroSection = () => {
  return (
    <div id="Home" className="relative h-screen hero-section"> {/* Removed gradient and added the hero-section class */}
      {/* Fixed Centered Logo */}
      <div className="center-logo-container">
        <img src={logo} alt="Logo" className="center-logo" />
      </div>

      {/* Circular Animation Container */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="orbit-container">
          {/* Orbiting Images */}
          <img src={amla} alt="amla" className="orbit-item orbit-1" />
          <img src={bael} alt="bael" className="orbit-item orbit-2" />
          <img src={carrot} alt="carrot" className="orbit-item orbit-3" />
          <img src={garlic} alt="garlic" className="orbit-item orbit-4" />
          <img src={ginger} alt="ginger" className="orbit-item orbit-5" />
          <img src={karanda} alt="karanda" className="orbit-item orbit-6" />
          <img src={lemon} alt="lemon" className="orbit-item orbit-7" />
          <img src={mango} alt="mango" className="orbit-item orbit-8" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
