import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

const SocialMedia = () => {
  return (
    <section className="bg-gray-50 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-6">Follow Us</h2>
        <p className="text-lg text-gray-600 mb-8">Stay connected with us through our social media platforms.</p>

        <div className="flex justify-center space-x-8">
          {/* Instagram */}
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center group"
          >
            <div className="bg-gradient-to-r from-orange-400 to-pink-500 p-4 rounded-full transition-all transform group-hover:scale-110 group-hover:shadow-lg">
              <FontAwesomeIcon icon={faInstagram} size="2x" className="text-white" />
            </div>
            <span className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-orange-500 transition-colors">Instagram</span>
          </a>

          {/* Facebook */}
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center group"
          >
            <div className="bg-blue-600 p-4 rounded-full transition-all transform group-hover:scale-110 group-hover:shadow-lg">
              <FontAwesomeIcon icon={faFacebook} size="2x" className="text-white" />
            </div>
            <span className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-blue-600 transition-colors">Facebook</span>
          </a>

          {/* YouTube */}
          <a
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center group"
          >
            <div className="bg-red-600 p-4 rounded-full transition-all transform group-hover:scale-110 group-hover:shadow-lg">
              <FontAwesomeIcon icon={faYoutube} size="2x" className="text-white" />
            </div>
            <span className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-red-600 transition-colors">YouTube</span>
          </a>

          {/* Twitter */}
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center group"
          >
            <div className="bg-blue-400 p-4 rounded-full transition-all transform group-hover:scale-110 group-hover:shadow-lg">
              <FontAwesomeIcon icon={faTwitter} size="2x" className="text-white" />
            </div>
            <span className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-blue-400 transition-colors">Twitter</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
