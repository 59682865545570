import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  // State to manage form inputs
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  
  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Validate form inputs
  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!form.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    }

    // Email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!form.email || !emailRegex.test(form.email)) {
      tempErrors.email = "Valid email is required";
      isValid = false;
    }

    // Phone validation
    const phoneRegex = /^[0-9]{10}$/;
    if (!form.phone || !phoneRegex.test(form.phone)) {
      tempErrors.phone = "Valid 10-digit phone number is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      emailjs.sendForm('your_service_id', 'your_template_id', e.target, 'your_user_id')
        .then((result) => {
          console.log(result.text);
          alert('Form submitted successfully!');
          setForm({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        }, (error) => {
          console.log(error.text);
          alert('Error submitting form.');
        });
    }
  };

  return (
    <section id="contact" className="bg-gray-50 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-primary">Contact Us</h2>
        <p className="mt-4 text-gray-600">Reach out to us for any inquiries or orders.</p>

        {/* Wrapper with a transparent background */}
        <div className="bg-primary bg-opacity-20 p-8 rounded-lg mt-8">
          <form className="mt-8" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="border border-gray-300 rounded-lg p-2 w-full mb-2"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}

            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="border border-gray-300 rounded-lg p-2 w-full mb-2"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}

            <input
              type="text"
              name="phone"
              value={form.phone}
              onChange={handleChange}
              placeholder="Your Phone Number"
              className="border border-gray-300 rounded-lg p-2 w-full mb-2"
            />
            {errors.phone && <p className="text-red-500">{errors.phone}</p>}

            <textarea
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="Your Message"
              className="border border-gray-300 rounded-lg p-2 w-full mb-2"
            />
            {errors.message && <p className="text-red-500">{errors.message}</p>}

            <button type="submit" className="bg-primary text-white rounded-lg py-2 px-4 w-full mt-2">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
