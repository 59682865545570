import React from 'react';

const ProductCard = ({ image, name, description }) => {
  return (
    <div className="border rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-1 hover:shadow-2xl">
      <img src={image} alt={name} className="w-full h-auto object-cover" />
      <div className="p-4">
        <h2 className="text-xl font-bold text-primary">{name}</h2>
        <p className="text-gray-700">{description}</p>
      </div>
    </div>
  );
};

export default ProductCard;
