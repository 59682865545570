import React from 'react';
import ProductCard from '../components/ProductCard';

// Correct image import paths
import mixVegPickle from '../assets/Mixvegpickle.png';
import mangoPickle from '../assets/Mangopickle.png';
import Lemonpickle from '../assets/Lemonpickle.png';
import Mangoraisins from '../assets/Mango Raisins Chutney.png';
import CarrotPickle from '../assets/Carrot pickle.png';
import figPickle from '../assets/Figpickle.png';
import Pineapple from '../assets/Pineapple.png';
import greenchilli from '../assets/greenchilli.png';
import mangomurraba from '../assets/Mangomurabba.png';
import MangoBafenu from '../assets/Mango Bafenu.png';
import lemonanddates from '../assets/lemonandDates.png';
import mangochunda from '../assets/mangochunda.png';


const Products = () => {
  const products = [
    { id: 1, name: 'Mix Veg Pickle', description: 'A tangy mix of seasonal vegetables and spices.', image: mixVegPickle },
    { id: 2, name: 'Mango Pickle', description: 'A spicy, tangy pickle made from raw mangoes.', image: mangoPickle },
    { id: 3, name: 'Lemon Pickle', description: 'A zesty, sour pickle made from fresh lemons.', image: Lemonpickle },
    { id: 4, name: 'Green Chilli Pickle', description: 'A fiery pickle made from green chillies.', image: greenchilli },
    { id: 5, name: 'Carrot Pickle', description: 'Crunchy carrots with a sweet, sour, and spicy twist.', image: CarrotPickle },
    { id: 6, name: 'Garlic Methiyu Pickle', description: 'A zesty pickle of garlic and fenugreek, bursting with bold spices.', image: figPickle },
    { id: 7, name: 'Pineapple Preserve', description: 'Sweet and tangy pineapple Preserve.', image: Pineapple },
    { id: 8, name: 'Mango Murabba', description: 'A sweet mango preserve.', image: mangomurraba },
    { id: 9, name: 'Lemon and Dates Chutney', description: 'Sweet dates blended with tangy lemon and a mix of aromatic spices.', image: lemonanddates },
    { id: 10, name: 'Mango Chunda', description: 'A sweet and spicy grated mango pickle.', image: mangochunda },
    { id: 11, name: 'Mango Raisins Chutney', description: 'Sweet mango chutney with a hint of raisins.', image: Mangoraisins },
    { id: 12, name: 'Mango Bafenu', description: 'Sweet and spicy mango bafenu with a unique flavor.', image: MangoBafenu },
  ];


  return (
    <section id="products" className="bg-gray-50 py-12">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-primary text-center mb-8">Our Products</h1>
        {/* Grid layout for displaying 4 products in a row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              image={product.image}
              name={product.name}
              description={product.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
