import React from 'react';
import AbhinavImage from '../assets/Abhinav.png'; // replace with the actual path and image file
import PrateekImage from '../assets/Prateek.jpg'; // replace with the actual path and image file
import SurbhiImage from '../assets/Surbhi.png'; 

const testimonials = [
    {
      id: 1,
      name: 'Abhinav Raj',
      title: 'Happy Customer',
      feedback: 'The mango pickle is absolutely delicious! The best I’ve ever tasted.',
      image: AbhinavImage,
    },
    {
      id: 2,
      name: 'Prateek Verma',
      title: 'Loyal Customer',
      feedback: 'I love how FARAMZ keeps their products organic and traditional. A must-have in my kitchen!',
      image: PrateekImage,
    },
    {
      id: 3,
      name: 'Surbhi Sharma',
      title: 'Food Blogger',
      feedback: 'FARAMZ pickles add that perfect tangy kick to my recipes. Highly recommend!',
      image: SurbhiImage,
    },
];

const Testimonial = () => {
  return (
    <section id="testimonials" className="bg-gray-50 py-8 sm:py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-primary text-center mb-6 sm:mb-8">
          What Our Customers Say
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="bg-white shadow-lg rounded-lg p-4 sm:p-6 text-center transform transition-transform hover:scale-105 duration-300 ease-out">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-20 h-20 sm:w-24 sm:h-24 rounded-full mx-auto mb-4 object-cover"
              />
              <h2 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">{testimonial.name}</h2>
              <p className="text-sm sm:text-base text-gray-500 mb-1">{testimonial.title}</p>
              <p className="text-sm sm:text-base text-gray-700 leading-snug">"{testimonial.feedback}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
